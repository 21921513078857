/*
*@ClassName EventService
*@Author 陈朋生
*@Version V1.0
*@Creation 2020/03/06
* */

import { Injectable } from '@angular/core';

import { EventEmitter } from 'eventemitter3';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  public event: any;
  eventEmit: any;

  constructor() {

    this.event = new EventEmitter();   // 这个实例就会被多个组件共享  来实现不同页面的数据通信
   }
}
