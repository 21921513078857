
/*
*@ClassName CommonService
*@Author 陈朋生
*@Version V1.0
*@Creation 2020/03/06
* */

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageService} from '../services/storage.service';
import {ServiceService} from '../services/service.service';

// tslint:disable-next-line:ban-types
let userdata: Object = null;
let tilte = '';

const Status: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public config: any = {
     domain: 'http://ablesmart.cn:3001/'
    // domain: 'http://localhost:3001/'
  };

  constructor(private server: ServiceService, public http: HttpClient, public storage: StorageService) {

  }

  // tslint:disable-next-line:ban-types
  ajaxGet(url: String) {

    const api = this.config.domain + url;
    return new Promise((resove, reject) => {

      this.http.get(api).subscribe((response) => {
        resove(response);
      }, (error) => {
        reject(error);
      });
    });
  }


  // tslint:disable-next-line:ban-types
  ajaxPost(url: String, json: Object) {
    const api = this.config.domain + url;
    return new Promise((resove, reject) => {
      let token = '';
      const userinfo = this.storage.get('userinfo1');
      if (userinfo !== null) {token = userinfo.token; }
      // console.log(api, token);
      const headers = new HttpHeaders({
          Authorization: token
        });

      this.http.post(api, json, {headers}).subscribe((response) => {
        resove(response);
      }, (error) => {
        reject(error);
      });
    });
  }




// -----------------------------------------------------------------------------------------------
  rPos(strObj: string, pos: number, replacetext: string) {
    const str = strObj.substr(0, pos) + replacetext + strObj.substring(pos + replacetext.length, strObj.length);
    return str;
  }

  // tslint:disable-next-line:ban-types
  userData() {
    return new Promise(resolve => {
      const api = this.config.domain + 'api/smstmalldata';
      const userinfo = this.storage.get('userinfo1');
      const headers = new HttpHeaders({
        Authorization: userinfo.token
      });
      this.http.post(api, {mac: userinfo.userInfo.mac}, {headers}).subscribe((response) => {
        // @ts-ignore
        if (response.success) {

          const obj: any = response;
          obj.data.data[0].dat = JSON.parse(obj.data.data[0].dat);
          userdata = obj.data.data[0];
          console.log(userdata);
          const obj1: any = userdata;
          tilte = `${obj1.hotel_name}--${obj1.room_name}`;
          // tilte = `CC智臻酒店--${obj1.room_name}`;
          let i = 0;
          obj1.dat.dev1.forEach((value) => {
            obj1.dat.dev1[i].number = 0;
            console.log(value);
            i++;
          });

          resolve(userdata);

        }
      }, (error) => {
        console.log(error);
      });
    });
  }
// =====================================================================
   getTilte() {
     return tilte;
   }


  updateTime() {

    const index = 0;
    const flag = false;

    return flag;
  }

// =====================================================================


  getUserData() {
    return userdata;
  }

  updateData(dat: any) {
    const d: any =  userdata;
    if (d.dat) {
      d.dat = dat;
      userdata = d;
    }
  }




  // ============================================================================

    setStatus(num: number , index: number) {
       if (index > 9) {return; }
       Status[index] = num;
    }

    getStatus() {
    return Status;
    }

  getStatusStr() {
    // tslint:disable-next-line:prefer-const
    let list = [];
    Status.forEach(value => {

      const  dd = this.server.StatusNumber(value);
     // console.log(value,  dd);
      list.push(dd);

    });
    return list;
  }
  // ============================================================================
}
