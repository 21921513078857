/*
*@ClassName ServiceService
*@Author 陈朋生
*@Version V1.0
*@Creation 2020/03/06
* */
import { Injectable } from '@angular/core';

const  Win = {
  width: 0,
  height: 0
};

let currentPopover = null;

let aircon_num = 1;

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor() { }
  // ====================================================================
   setWin(width: any, height: any) {
    Win.width = width;
    Win.height = height;
   }

   getWin(): any {
     return Win;
   }

   setpopover(popover: any) {
     currentPopover = popover;
   }

  getpopover(): any {
    return currentPopover;
  }

  setAirconNum(num: any) {
    aircon_num = num;
  }


  getAirconNum(): any {
    return aircon_num;
  }

  // ==============================================================

  StatusNumber(num: number) {
    const totalBit = 3;
    const nn = num.toString(10);
    if (nn.length >= totalBit) {
      return nn;
    }
    let rtn = '';
    for (let i = 0; i < totalBit; i++) {
      rtn += '0';
    }
    rtn += nn;
    rtn = rtn.substr(rtn.length - totalBit);
    return rtn;
  }

  roomId(str: any) {// 转换房号
    const totalBit = 4;

    const num = parseInt(str, 10);
    const nn = num.toString(16);
    if (nn.length >= totalBit) {
      return nn;
    }
    let rtn = '';
    for (let i = 0; i < totalBit; i++) {
      rtn += '0';
    }
    rtn += nn;
    rtn = rtn.substr(rtn.length - totalBit);
    return rtn;
  }

  // 替接字符串
  replacePos(strObj: string, pos: number, replacetext: string) {
    const str = strObj.substr(0, pos) + replacetext + strObj.substring(pos + replacetext.length, strObj.length);
    return str;
  }


  switData(str, room, swdat, onoff) {
    const type = swdat.substr(0, 2);
    const sid = swdat.substr(2, 2);
    const sbit = swdat.substr(4, 1);
    let strObj = str;
    strObj = this.replacePos(strObj, 4, room);
    strObj = this.replacePos(strObj, 2, type);
    strObj = this.replacePos(strObj, 8, sid);
    strObj = this.replacePos(strObj, 12, sbit);
    strObj = this.replacePos(strObj, 13, onoff);

    return strObj;
  }

  uproom(str, room) {
    let strObj = str;
    strObj = this.replacePos(strObj, 4, room);
    return strObj;
  }


}
