import { Component, OnInit } from '@angular/core';
import {EventService} from '../../services/event.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {

  constructor(private eventService: EventService) { }

  ngOnInit() {
  }

  Popover(num: number) {
    this.eventService.event.emit('popover', {comm: 'close', para: num});
  }

}
