// @ts-ignore
import { Component } from '@angular/core';
// @ts-ignore
import { Platform } from '@ionic/angular';
// @ts-ignore
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import {ServiceService} from './services/service.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public serice: ServiceService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      const width = window.screen.width;
      const height = window.screen.height;
      this.serice.setWin(width, height);
    });
  }
}
