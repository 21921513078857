import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// @ts-ignore
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// @ts-ignore
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PopoverComponent } from './components/popover/popover.component';

// http相关模块
// @ts-ignore
import { HttpClientModule } from '@angular/common/http';
import {MqttModule, MqttService} from 'ngx-mqtt';

// 引入配置公共服务
import { CommonService } from './services/common.service';
import { StorageService } from './services/storage.service';


@NgModule({
  declarations: [
    AppComponent,
    PopoverComponent
  ],
  entryComponents: [PopoverComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    MqttModule.forRoot({
      connectOnCreate: false,
      hostname: 'ablesmart.cn',
      port: 61614,
      username: 'myable',
      password: 'myable123'
    }),
    HttpClientModule
  ],
  providers: [
    CommonService,
    StorageService,
    MqttService,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
